var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-rounded",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.dataList,"disable-sort":""},scopedSlots:_vm._u([{key:"item.credit_type",fn:function(ref){
var item = ref.item;
return [(item.credit_type=='spend')?_c('span',{staticStyle:{"color":"#A00"}},[_vm._v(_vm._s(item.credit_type))]):_vm._e(),(item.credit_type=='add')?_c('span',{staticStyle:{"color":"#0A0"}},[_vm._v(_vm._s(item.credit_type))]):_vm._e()]}},{key:"item.credit_amount",fn:function(ref){
var item = ref.item;
return [(item.credit_type=='spend')?_c('span',{staticStyle:{"color":"#A00"}},[_vm._v(_vm._s(item.credit_amount))]):_vm._e(),(item.credit_type=='add')?_c('span',{staticStyle:{"color":"#0A0"}},[_vm._v(_vm._s(item.credit_amount))]):_vm._e()]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [(item.icon)?_c('v-img',{staticClass:"my-1 icon-image",attrs:{"src":item.icon,"width":"80","height":"80"}}):_vm._e(),(!item.icon)?_c('v-img',{staticClass:"my-1 icon-image",attrs:{"src":require('@/assets/images/images/no_image.png'),"width":"80","height":"80"}}):_vm._e()]}},{key:"item.registration_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format_date")(item.registration_date)))])]}},{key:"item.create_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format_date")(item.create_date)))])]}},{key:"item.agreement_create_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format_date")(item.agreement_create_date)))])]}},{key:"item.timesheet_create_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format_date")(item.timesheet_create_date)))])]}},{key:"item.complete_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format_date")(item.complete_date)))])]}},{key:"item.job_type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format_job_type")(item.job_type)))])]}},{key:"item.job_type_status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format_status")(item.job_type_status)))])]}},{key:"item.working_dates",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.working_dates.join(', ')))])]}},{key:"item.worker_status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format_status")(item.worker_status)))])]}},{key:"item.worker_approval_status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format_status")(item.worker_approval_status)))])]}},{key:"item.news_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"padding":"10px 0"}},[_c('v-select',{attrs:{"items":_vm.worker_status,"item-text":"item-text","item-value":"item-value","outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.NewsStatusChange($event, item.news_id)}},model:{value:(item.news_status),callback:function ($$v) {_vm.$set(item, "news_status", $$v)},expression:"item.news_status"}})],1)]}},{key:"item.customer_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"padding":"10px 0"}},[_c('v-select',{attrs:{"items":_vm.customer_status,"item-text":"item-text","item-value":"item-value","outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.CustomerStatusChange($event, item.customer_id)}},model:{value:(item.customer_status),callback:function ($$v) {_vm.$set(item, "customer_status", $$v)},expression:"item.customer_status"}})],1)]}},{key:"item.view_worker_button",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('router-link',{attrs:{"to":{ name: 'WorkerDetail', params: { id: item.worker_id } }}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('general.view_detail'))+" ")])],1)],1)]}},{key:"item.view_customer_button",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('router-link',{attrs:{"to":{ name: 'CustomerDetail', params: { id: item.customer_id } }}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('general.view_detail'))+" ")])],1)],1)]}},{key:"item.view_job_detail_button",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('router-link',{attrs:{"to":{ name: 'JobPostDetail', params: { id: item.job_id } }}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('general.view_detail'))+" ")])],1)],1)]}},{key:"item.view_news_detail_button",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('router-link',{attrs:{"to":{ name: 'NewsDetail', params: { id: item.news_id } }}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('general.view_detail'))+" ")])],1)],1)]}},{key:"item.payment_record_view_agreement_button",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('router-link',{attrs:{"to":{ name: 'JobPostDetail', params: { id: item.job_id } }}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('general.view_detail'))+" "+_vm._s(_vm.$t('job_post.agreement'))+" ")])],1)],1)]}},{key:"item.payroll_view_button",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('router-link',{attrs:{"to":{ name: 'PayrollDetail', params: { id: item.payroll_id } }}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('general.view_detail'))+" ")])],1)],1)]}},{key:"item.view_button",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: item.route_link, params: { id: item.id } }}},[_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v(" Detail ")])],1)],1)]}},{key:"item.custom_actions_field",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.handleCustomOption('edit', item, index)}}},[_vm._v(" 更改 ")])],1),_c('span',[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.handleCustomOption('delete', item, index)}}},[_vm._v(" 刪除 ")])],1)]}}],null,true)}),_c('SnackBar',{attrs:{"text":_vm.snack_bar_text,"snackbar":_vm.snack_bar_open},on:{"close":_vm.updateSnackBar}}),_c('Dialog',{attrs:{"dialog":_vm.dialog_open,"text":_vm.dialog_text},on:{"update:dialog":function($event){_vm.dialog_open=$event},"close":_vm.getDialogValue}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }