// for data that does not need to store consistently

import axios from 'axios'

const axiosURI = 'https://thistownneeds.online/Backend/request.php'

export const getData = async (params) => {
    const response = await axios.post(axiosURI, params.toString())
    let result = response.data;
    if (result.result == "success") {
        return Promise.resolve(result.data);
    }
    return Promise.reject(result.data);
}

export const postData = async (params, config = null) => {
    let response;
    if (config != null) {
      response = await axios.post(axiosURI, params.toString(), config)
    } else {
      response = await axios.post(axiosURI, params.toString())
    }

    let result = response.data;
    if (result.result == "success") {
        return Promise.resolve(result.data);
    }
    return Promise.reject("Post data fail: " + result.data);
}

export const uploadFile = async (formData) => {
    let response = await axios.post(axiosURI, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  
    let result = response.data;
    if (result.result == "success") {
      return Promise.resolve(result.data);
    }
    return Promise.reject(result.data);
  }