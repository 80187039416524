<template>
  <v-container class="alert_pop">
    <v-alert width="250px" v-model="showStatus" :type="alert_type" transition="slide-x-reverse-transition">{{
      text
    }}</v-alert>
  </v-container>
</template>

<script>
export default {
  name: 'AlertBox',
  props: ['type', 'text', 'show'],
  data: () => ({
    showStatus: false,
    timeout: -1,
    timeOutClock: null,
    alert_type: 'success',
  }),
  watch: {
    show: function (newVal, oldVal) {
      this.showStatus = newVal
      if (this.timeOutClock != null) {
        clearTimeout(this.timeOutClock)
      }

      this.timeOutClock = setTimeout(() => {
        this.closeAlertBox()
      }, 2000)
    },
    type: function (newVal, oldVal) {
      this.alert_type = newVal
    },
  },
  methods: {
    closeAlertBox() {
      this.showStatus = false
      this.$emit('close', this.showStatus)
      clearTimeout(this.timeOutClock)
      this.timeOutClock = null
    },
  },
  beforeDestroy() {
    if (this.timeOutClock != null) {
      clearTimeout(this.timeOutClock)
    }
  },
}
</script>