<template>
  <div class="text-center ma-2">
    <v-snackbar v-model="snackBarStatus" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="closeSnackBar"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'SnackBar',
  props: ['text', 'snackbar'],
  data: () => ({
    snackBarStatus: false,
    timeout: -1,
    timeOutClock: null,
  }),
  watch: {
    snackbar: function (newVal, oldVal) {
      this.snackBarStatus = newVal
      if (this.timeOutClock != null) {
        clearTimeout(this.timeOutClock)
      }

      this.timeOutClock = setTimeout(() => {
        this.closeSnackBar()
      }, 5000)
    },
  },
  methods: {
    closeSnackBar() {
      this.snackBarStatus = false
      this.$emit('close', this.snackBarStatus)
      clearTimeout(this.timeOutClock)
      this.timeOutClock = null
    },
  },
  beforeDestroy() {
    if (this.timeOutClock != null) {
      clearTimeout(this.timeOutClock)
    }
  },
}
</script>
