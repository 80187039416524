<template>
  <v-card>
    <v-data-table :loading="loading" :headers="headers" :items="dataList" class="table-rounded" disable-sort>
      <!--   TODO:    :options.sync="option"
      :server-items-length="itemLength"
      -->

      <template v-slot:[`item.credit_type`]="{ item }">
        <span style="color:#A00" v-if="item.credit_type=='spend'">{{ item.credit_type }}</span>
        <span style="color:#0A0" v-if="item.credit_type=='add'">{{ item.credit_type }}</span>
      </template>

      <template v-slot:[`item.credit_amount`]="{ item }">
        <span style="color:#A00" v-if="item.credit_type=='spend'">{{ item.credit_amount }}</span>
        <span style="color:#0A0" v-if="item.credit_type=='add'">{{ item.credit_amount }}</span>
      </template>

      <template v-slot:[`item.icon`]="{ item }">
        <v-img :src="item.icon" width="80" height="80" class="my-1 icon-image" v-if="item.icon"/>
        <v-img :src="require('@/assets/images/images/no_image.png')" width="80" height="80" class="my-1 icon-image" v-if="!item.icon"/>
      </template>

      <template v-slot:[`item.registration_date`]="{ item }">
        <span>{{ item.registration_date | format_date }}</span>
      </template>

      <template v-slot:[`item.create_date`]="{ item }">
        <span>{{ item.create_date | format_date }}</span>
      </template>

      <template v-slot:[`item.agreement_create_date`]="{ item }">
        <span>{{ item.agreement_create_date | format_date }}</span>
      </template>

      <template v-slot:[`item.timesheet_create_date`]="{ item }">
        <span>{{ item.timesheet_create_date | format_date }}</span>
      </template>

      <template v-slot:[`item.complete_date`]="{ item }">
        <span>{{ item.complete_date | format_date }}</span>
      </template>

      <template v-slot:[`item.job_type`]="{ item }">
        <span>{{ item.job_type | format_job_type }}</span>
      </template>

      <template v-slot:[`item.job_type_status`]="{ item }">
        <span>{{ item.job_type_status | format_status }}</span>
      </template>

      <template v-slot:[`item.working_dates`]="{ item }">
        <span>{{ item.working_dates.join(', ') }}</span>
      </template>

      <template v-slot:[`item.worker_status`]="{ item }">
        <span>{{ item.worker_status | format_status }}</span>
      </template>

      <template v-slot:[`item.worker_approval_status`]="{ item }">
        <span>{{ item.worker_approval_status | format_status }}</span>
      </template>

      <!-- <template v-slot:[`item.worker_status`]="{ item }">
        <div style="padding: 10px 0">
          <v-select
            :items="worker_status"
            item-text="item-text"
            item-value="item-value"
            outlined
            dense
            v-model="item.worker_status"
            hide-details
            @change="WorkerStatusChange($event, item.worker_id)"
          ></v-select>
        </div>
      </template> -->

      <template v-slot:[`item.news_status`]="{ item }">
        <div style="padding: 10px 0">
          <v-select
            :items="worker_status"
            item-text="item-text"
            item-value="item-value"
            outlined
            dense
            v-model="item.news_status"
            hide-details
            @change="NewsStatusChange($event, item.news_id)"
          ></v-select>
        </div>
      </template>

      <template v-slot:[`item.customer_status`]="{ item }">
        <div style="padding: 10px 0">
          <v-select
            :items="customer_status"
            item-text="item-text"
            item-value="item-value"
            outlined
            dense
            v-model="item.customer_status"
            hide-details
            @change="CustomerStatusChange($event, item.customer_id)"
          ></v-select>
        </div>
      </template>

      <!-- <template v-slot:[`item.worker_approval_status`]="{ item }">
        <div style="padding: 10px 0">
          <v-select
            :items="worker_approval_status"
            item-text="item-text"
            item-value="item-value"
            outlined
            dense
            v-model="item.worker_approval_status"
            hide-details
            @change="WorkerApprovalStatusChange($event, item.worker_id)"
          ></v-select>
        </div>
      </template> -->

      <template v-slot:[`item.view_worker_button`]="{ item }">
        <span>
          <router-link :to="{ name: 'WorkerDetail', params: { id: item.worker_id } }">
            <v-btn color="primary" depressed outlined>
              {{ $t('general.view_detail') }}
            </v-btn>
          </router-link>
        </span>
      </template>

      <template v-slot:[`item.view_customer_button`]="{ item }">
        <span>
          <router-link :to="{ name: 'CustomerDetail', params: { id: item.customer_id } }">
            <v-btn color="primary" depressed outlined>
              {{ $t('general.view_detail') }}
            </v-btn>
          </router-link>
        </span>
      </template>

      <template v-slot:[`item.view_job_detail_button`]="{ item }">
        <span>
          <router-link :to="{ name: 'JobPostDetail', params: { id: item.job_id } }">
            <v-btn color="primary" depressed outlined>
              {{ $t('general.view_detail') }}
            </v-btn>
          </router-link>
        </span>
      </template>

      <template v-slot:[`item.view_news_detail_button`]="{ item }">
        <span>
          <router-link :to="{ name: 'NewsDetail', params: { id: item.news_id } }">
            <v-btn color="primary" depressed outlined>
              {{ $t('general.view_detail') }}
            </v-btn>
          </router-link>
        </span>
      </template>

      <template v-slot:[`item.payment_record_view_agreement_button`]="{ item }">
        <span>
          <router-link :to="{ name: 'JobPostDetail', params: { id: item.job_id } }">
            <v-btn color="primary" depressed outlined>
              {{ $t('general.view_detail') }} {{ $t('job_post.agreement') }}
            </v-btn>
          </router-link>
        </span>
      </template>

      <template v-slot:[`item.payroll_view_button`]="{ item }">
        <span>
          <router-link :to="{ name: 'PayrollDetail', params: { id: item.payroll_id } }">
            <v-btn color="primary" depressed outlined>
              {{ $t('general.view_detail') }}
            </v-btn>
          </router-link>
        </span>
      </template>


      <template v-slot:[`item.view_button`]="{ item }">
        <span>
          <router-link :to="{ name: item.route_link, params: { id: item.id } }" style="text-decoration: none">
            <v-btn text color="primary"> Detail </v-btn>
          </router-link>
        </span>
      </template>
      
      <template v-slot:[`item.custom_actions_field`]="{ item, index }">
        <span>
          <v-btn color="success" text @click="handleCustomOption('edit', item, index)"> 更改 </v-btn>
        </span>
        <span>
          <v-btn color="error" text @click="handleCustomOption('delete', item, index)"> 刪除 </v-btn>
        </span>
      </template>
      <!-- 
      <template v-slot:[`item.announcement_button`]="{ item }">
        <div class="d-flex align-center justify-space-around">
          <div>
            <router-link :to="{ name: 'editAnnouncement', params: { id: item.id } }">
              <v-btn color="accent" small>
                {{ $t('general.edit') }}
              </v-btn>
            </router-link>
          </div>
          <div>
            <v-btn color="error" @click="removeAnnouncement(item.id)" small>
              {{ $t('general.delete') }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:[`item.jobs_button`]="{ item }">
        <div class="d-flex align-center justify-space-around">
          <div>
            <router-link :to="{ name: 'editJob', params: { id: item.id } }">
              <v-btn color="accent" small>
                {{ $t('general.edit') }}
              </v-btn>
            </router-link>
          </div>
          <div class="">
            <v-btn color="error" @click="removeJob(item.id)" small>
              {{ $t('general.delete') }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:[`item.report_button`]="{ item }">
        <div class="d-flex align-center justify-space-around">
          <div>
            <router-link :to="{ name: 'editReport', params: { id: item.id } }">
              <v-btn color="accent" small>
                {{ $t('general.edit') }}
              </v-btn>
            </router-link>
          </div>
          <div>
            <v-btn color="error" @click="removeReport(item.id)" small>
              {{ $t('general.delete') }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:[`item.media_coverage_button`]="{ item }">
        <div class="d-flex align-center justify-space-around">
          <div>
            <router-link :to="{ name: 'editMediaCoverage', params: { id: item.id } }">
              <v-btn color="accent" small>
                {{ $t('general.edit') }}
              </v-btn>
            </router-link>
          </div>
          <div>
            <v-btn small color="error" @click="removeMediaCoverage(item.id)">
              {{ $t('general.delete') }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:[`item.press_release_button`]="{ item }">
        <div class="d-flex align-center justify-space-around">
          <div>
            <router-link :to="{ name: 'editPressRelease', params: { id: item.id } }">
              <v-btn color="accent" small>
                {{ $t('general.edit') }}
              </v-btn>
            </router-link>
          </div>
          <div>
            <v-btn color="error" @click="removePressRelease(item.id)" small>
              {{ $t('general.delete') }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:[`item.presentation_button`]="{ item }">
        <div class="d-flex flex-wrap align-center flex-sm-column flex-md-row flex-lg-row flex-xl-row pa-sm-2">
          <div>
            <router-link :to="{ name: 'editPresentation', params: { id: item.id } }">
              <v-btn color="accent">
                {{ $t('general.edit') }}
              </v-btn>
            </router-link>
          </div>
          <div class="ml-xl-4 ml-lg-4 ml-md-4 mt-sm-2 mt-md-0 mt-lg-0 mt-xl-0">
            <v-btn color="error" @click="removePresentation(item.id)">
              {{ $t('general.delete') }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:[`item.analyst_coverage_button`]="{ item }">
        <div class="d-flex flex-wrap align-center flex-sm-column flex-md-row flex-lg-row flex-xl-row pa-sm-2">
          <div>
            <router-link :to="{ name: 'editAnalystCoverage', params: { id: item.id } }">
              <v-btn color="accent">
                {{ $t('general.edit') }}
              </v-btn>
            </router-link>
          </div>
          <div class="ml-xl-4 ml-lg-4 ml-md-4 mt-sm-2 mt-md-0 mt-lg-0 mt-xl-0">
            <v-btn color="error" @click="removeAnalystCoverage(item.id)">
              {{ $t('general.delete') }}
            </v-btn>
          </div>
        </div>
      </template> -->
    </v-data-table>
    <SnackBar :text="snack_bar_text" :snackbar="snack_bar_open" v-on:close="updateSnackBar" />
    <Dialog :dialog.sync="dialog_open" v-on:close="getDialogValue" :text="dialog_text" />
  </v-card>
</template>

<script>
import { mdiEye } from '@mdi/js'
import Dialog from '@/components/Dialog'
import SnackBar from '@/components/SnackBar'
import { postData } from '@/api'

export default {
  name: 'Table',
  props: ['headers', 'dataList', 'loading', 'options', 'itemLength'],
  components: {
    Dialog,
    SnackBar,
  },
  data: () => ({
    items: [],
    eye_icon: mdiEye,
    option: {},
    list: [],
    dialog_open: false,
    dialog_text: '',
    dialog_type: '',
    item_id: -1,
    snack_bar_text: '',
    snack_bar_open: false,
    worker_status: [],
    worker_approval_status: [],
    customer_status: [],
  }),
  watch: {
    dataList: function (newVal, oldVal) {
      console.log(newVal)
      this.list = JSON.parse(JSON.stringify(newVal))
    },
    options: function (newVal, oldVal) {
      Object.assign(this.option, newVal)
    },
  },
  methods: {

    WorkerStatusChange(event, id) {},
    WorkerApprovalStatusChange(event, id) {},
    CustomerStatusChange(event, id) {},
    NewsStatusChange(event, id) {},
    updateSnackBar(status) {
      this.snack_bar_open = status
    },
    handleCustomOption(action, item, index) {
      this.$emit('closeCustomOption', action, item, index)
    },
    async getDialogValue(action) {
      this.dialog_open = false
      if (action) {
        if (this.dialog_type != '' && this.item_id != -1) {
        }
      }
    },
  },
  created() {

  },
}
</script>

<style lang="scss" scoped>
 .icon-image{
   border-radius:50%;
 }
</style>

