<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="600">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
      <v-card>
        <v-card-title> {{ text }} </v-card-title>
        <v-card-text>
          <slot v-if="!isDeleteDialog"></slot>
          <div v-else>你是否要刪除此項目</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="dialogAction(false)"> 取消 </v-btn>
          <v-btn color="error darken-1" text @click="dialogAction(true)"> 確定 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'Dialog',
  props: ['dialog', 'text', 'isDeleteDialog', 'max_width', 'loading'],
  data: () => ({
    openDialog: false,
    width: 600,
    submit_loading: false,
  }),
  watch: {
    dialog: function (newVal, oldVal) {
      console.log(newVal)
      this.openDialog = newVal
    },
    max_width: function (newVal, oldVal) {
      this.width = newVal
    },
    loading: function (newVal, oldVal) {
      this.submit_loading = newVal
    },
  },
  methods: {
    dialogAction(action) {
      this.$emit('close', action)
    },
  },
}
</script>
