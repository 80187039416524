export const MEDIA_PATH = "https://thistownneeds.online/ttn_media/"

export const globalFunc = {
    DataValid(data) {
        if (data != undefined && data != null && data != "") {
            return true;
        } else {
            return false;
        }
    },
    formatMoney: function (number) {
        number = Math.round(number * 100) / 100;

        let decPlaces = 2;
        let decSep = ".";
        let thouSep = ",";
        var sign = number < 0 ? "-" : "";
        var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
        var j = (j = i.length) > 3 ? j % 3 : 0;

        return sign +
            (j ? i.substr(0, j) + thouSep : "") +
            i.substr(j).replace(/(\decSep{4})(?=\decSep)/g, "$1" + thouSep) +
            (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
    },
    getServerImageHead() {
        return IMAGE_PATH
    },
    firstFileToBase64(fileImage) {
      return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        if (fileReader && fileImage != null) {
          fileReader.readAsDataURL(fileImage);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        } else {
          reject(new Error("No file found"));
        }
      });
    },
    getDateStr(date) {
      if (globalFunc.DataValid(date)) {
        return date.split('T')[0]
      }
  
      return ''
    },
    getTimeStr(date) {
      if (globalFunc.DataValid(date)) {
        return date.split('T')[1].replace('Z', '')
      }
  
      return ''
    },
    getDateTimeStr(date) {
      if (globalFunc.DataValid(date)) {
        return date.split('T')[0] + ' ' + date.split('T')[1].replace('Z', '')
      }
  
      return ''
    },
}

export const Formatter = {
    filter: {
        numOnly: function (s) { return s.replace(/[^0-9]/gi, ''); },
        alphaOnly: function (s) { return s.replace(/[^a-z]/gi, ''); },
        alphaNumeric: function (s) { return s.replace(/[^a-z0-9]/gi, ''); },
        noWhiteSpace: function (s) { return s.replace(/\s/g, ''); }
    },
    escape: {
        html: function (s) { return s.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;"); },
        regex: function (s) { return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); },
    },
    convert: {
        byte: function (n, p) { if (n === 0) return '0B'; var p = p || 1; const k = 1024; const s = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; const i = Math.floor(Math.log(n) / Math.log(k)); return parseFloat((n / Math.pow(k, i)).toFixed(p)) + s[i]; }, // size int, precision (1)
    },
    replace: {
        linebreak: function (s, c) { return s.replace(/(?:\r\n|\r|\n)/g, c || '<br/>'); }, // string, replace char
    },
    separator: function (n, c, l) { var l = l || 3; var r = new RegExp("\\B(?=(\\d{" + l + "})+(?!\\d))", "g"); var p = n.toString().split("."); p[0] = p[0].replace(r, c || ","); return p.join("."); }, // string, separator char (,) , separator mask length (3)
    pad: function (n, l, c, r, b) { var s = n.toString(), l = l || 2, r = r !== false, b = b !== false; if (s.length > 0) { var p = ''; if (!isNaN(n) && s.charAt(0) == '-') { p = '-'; s = s.substring(1) }; while (s.length < l) { if (r) s = (c || '0') + s; else s += (c || '0'); } return b ? p + s : s; } else return ''; } // number, length (2), padder char (0), isPadLeft (YES), showNegative (YES)
}

export const Validator = {
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
    required: function (value) {
      if (Array.isArray(value)) {
        return value.length > 0
      }
      return globalFunc.DataValid(value)
    },
    isEmail: function(email) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(email)
    },
    validatePW: function (pw) {
      return Validator.password.test(pw)
    },
    isNumberOnly: value => {
      // true = number only
      const pattern = /^[0-9]+$/i
      return pattern.test(value)
    },
    isFloating: value => {
      const pattern = /^[0-9]+[.]?[0-9]+$/i
      return pattern.test(value)
    },
    isPositive: value => {
      return parseFloat(value) >= 0
    }
  }